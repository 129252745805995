import { useEffect, useMemo } from 'react'
// eslint-disable-next-line no-restricted-imports
import styled from 'styled-components' // to be fixed in VX-3719
import { subscriptionWidgetApi } from '@sevenrooms/core/api'
import { issueMessages } from '@sevenrooms/core/form'
import { CurrencyLocale, useLocales, Locale } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, Loader, Root, Spinner, VStack, CardSection, LogoFooter, TopNav, Image } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useMediaQuery } from '@sevenrooms/react-components/hooks/useMediaQuery'
import { routes } from '@sevenrooms/routes'
import { useStoreSelector } from '../../store'
import { subscriptionWidgetMessages } from '../../subscriptionWidgetMessages'
import { SubscribeComponent } from './SubscribeComponent'

function SubscribeSuccess({ redirectMessage = false }: { redirectMessage?: boolean }) {
  const theme = useTheme()
  const { emailSubscriptionConfirmationHeader } = useStoreSelector(state => state.selectedLanguageStrings)
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.m}px)`)

  return (
    <VStack display="flow" width="496px" spacing="m">
      <Box m="auto" width="fit-content">
        <CardSection
          height="50px"
          width={isMobile ? '100%' : '496px'}
          borderColor="borders"
          flexDirection="row"
          alignItems="center"
          spacing="s"
          p="m"
          top="xl"
        >
          <Icon color="success" size="lg" name="GX-checkbox-marked-circle" />
          <Text wordBreak="break-word">{emailSubscriptionConfirmationHeader}</Text>
        </CardSection>
      </Box>
      {redirectMessage && (
        <Box>
          <CardSection
            m="s"
            height="50px"
            width="100%"
            flexDirection="row"
            alignItems="center"
            spacing="s"
            p="m"
            top="xl"
            borderColor="primaryBackground"
          >
            <span id="spinner-wrapper" style={{ margin: '0 auto' }}>
              <Spinner />
            </span>
          </CardSection>
        </Box>
      )}
    </VStack>
  )
}

const WIDGET_CONTAINER_CONTENT_MAX_WIDTH = '1440px'

export function WidgetComponent() {
  const { matchQuery } = useNavigation()
  const { lang = 'en' } = matchQuery(routes.explore.subscription) ?? {}
  const { venueInfo, selectedLanguageStrings, subscriptionWidgetSettings } = useStoreSelector(state => state)
  const urlLanguageLocale = Locale.getLocale(lang)

  useEffect(() => {
    CurrencyLocale.setLocale(venueInfo.locale)
  }, [venueInfo.locale])

  const themeOverride = useMemo(
    () => ({
      colors: {
        brandColor: subscriptionWidgetSettings.colorButton, // header background color
        venueColor: subscriptionWidgetSettings.colorPrimary, // button background color
      },
    }),
    [subscriptionWidgetSettings.colorButton, subscriptionWidgetSettings.colorPrimary]
  )

  const messages = useMemo(
    () => ({
      ...selectedLanguageStrings,
      [subscriptionWidgetMessages.emailSubscriptionHeader.id]: selectedLanguageStrings.emailSubscriptionHeader,
      [subscriptionWidgetMessages.policyVenueGroupMarketing.id]: selectedLanguageStrings.policyVenueGroupMarketing,
      [subscriptionWidgetMessages.policyVenueSpecificMarketing.id]: selectedLanguageStrings.policyVenueSpecificMarketing,
      [subscriptionWidgetMessages.resConfirmPageDietaryRestrictQuestion.id]: selectedLanguageStrings.resConfirmPageDietaryRestrictQuestion,
      [subscriptionWidgetMessages.resWidgetBirthdayLabel.id]: selectedLanguageStrings.resWidgetBirthdayLabel,
      [subscriptionWidgetMessages.resWidgetCheckoutSubmitButtonLabel.id]: selectedLanguageStrings.resWidgetCheckoutSubmitButtonLabel,
      [subscriptionWidgetMessages.resWidgetDietaryRestrictionsLabel.id]: selectedLanguageStrings.resWidgetDietaryRestrictionsLabel,
      [subscriptionWidgetMessages.resWidgetEmailLabel.id]: selectedLanguageStrings.resWidgetEmailLabel,
      [subscriptionWidgetMessages.resWidgetFirstNameLabel.id]: selectedLanguageStrings.resWidgetFirstNameLabel,
      [subscriptionWidgetMessages.resWidgetLastNameLabel.id]: selectedLanguageStrings.resWidgetLastNameLabel,
      [subscriptionWidgetMessages.resWidgetPhoneNumberLabel.id]: selectedLanguageStrings.resWidgetPhoneNumberLabel,
      [subscriptionWidgetMessages.resWidgetPostalCodeLabel.id]: selectedLanguageStrings.resWidgetPostalCodeLabel,
      [subscriptionWidgetMessages.resWidgetSalutationLabel.id]: selectedLanguageStrings.resWidgetSalutationLabel,
      [issueMessages.required.id]: selectedLanguageStrings.resWidgetErrorsFieldRequired,
      [issueMessages.invalid.id]: selectedLanguageStrings.resWidgetErrorsFieldInvalid,
    }),
    [selectedLanguageStrings]
  )

  return (
    <Root theme="gx" themeOverride={themeOverride} messages={messages} locale={urlLanguageLocale}>
      <SubscriptionWidget />
    </Root>
  )
}

function SubscriptionWidget() {
  const { matchQuery } = useNavigation()
  const { embed } = matchQuery(routes.explore.subscription) ?? {}
  const { venueInfo, reservationWidgetSettings, subscriptionWidgetSettings } = useStoreSelector(state => state)
  const [signupMutation, { isSuccess, isLoading }] = subscriptionWidgetApi.useSignupMutation()
  const redirectUrlExist = !!subscriptionWidgetSettings.formSettings.redirectUrl?.trim()

  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const padding = isMobile ? 'm' : 'lm'

  useEffect(() => {
    const { redirectUrl } = subscriptionWidgetSettings.formSettings
    if (isSuccess && !isLoading && redirectUrlExist) {
      setTimeout(() => {
        window.parent.location.href = redirectUrl
      }, 2000)
    }
  }, [isSuccess, isLoading, redirectUrlExist, subscriptionWidgetSettings.formSettings])

  let headerImageUrl: string | undefined
  if (!embed && subscriptionWidgetSettings.showHeaderImage) {
    if (subscriptionWidgetSettings.headerImageIsResWidgetImage) {
      headerImageUrl = `/.h/download/${
        reservationWidgetSettings.headerImgPhotoDict.mega || reservationWidgetSettings.headerImgPhotoDict.medium
      }`
    } else {
      headerImageUrl = subscriptionWidgetSettings.headerImageUrl
    }
  }

  const venuePath = `/explore/${venueInfo.urlKey}/reservations/create/search`

  return (
    <VStack justifyContent="space-between" minHeight="100vh" backgroundColor="secondaryBackground">
      <VStack width="100%" flex="1">
        {!embed && (
          <TopNav
            logoLabel={venueInfo.name}
            baseUrl={venuePath}
            logo={subscriptionWidgetSettings.logoIsVenueLogo ? venueInfo.largeLogoUrl : subscriptionWidgetSettings.logoUrl || ''}
            grow={false}
          />
        )}
        {headerImageUrl && (
          <StyledImage
            alt={formatMessage(subscriptionWidgetMessages.headerAlt, {
              venue_name: venueInfo.name ?? formatMessage(subscriptionWidgetMessages.venue),
            })}
            src={headerImageUrl}
            height={150}
            width="100%"
            maxWidth={WIDGET_CONTAINER_CONTENT_MAX_WIDTH}
            centered
          />
        )}
        <Flex
          maxWidth={WIDGET_CONTAINER_CONTENT_MAX_WIDTH}
          width="100%"
          alignSelf="center"
          justifyContent="center"
          pl={padding}
          pr={padding}
          data-test="widget-content"
          flex="1"
          backgroundColor="primaryBackground"
        >
          {!isSuccess && !isLoading && <SubscribeComponent signupMutation={signupMutation} />}
          {isSuccess && !isLoading && !redirectUrlExist && <SubscribeSuccess />}
          {isLoading && (
            <Flex minHeight="274px" justifyContent="center" alignItems="center" data-test="loading-box">
              <Loader />
            </Flex>
          )}
          {redirectUrlExist && isSuccess && <SubscribeSuccess redirectMessage />}
        </Flex>
      </VStack>
      <LogoFooter />
    </VStack>
  )
}

const StyledImage = styled(Image)`
  align-self: center;
`
