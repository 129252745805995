import { combineReducers } from 'redux'
import { subscriptionWidgetApi } from '@sevenrooms/core/api'
import type { PreloadedState } from '../preloadedState'

export interface RootState extends PreloadedState {
  subscriptionWidgetApi: ReturnType<typeof subscriptionWidgetApi.reducer>
}

export const rootReducer = combineReducers({
  venueInfo: (state = {}) => state,
  reservationWidgetSettings: (state = {}) => state,
  subscriptionWidgetSettings: (state = {}) => state,
  selectedLanguageStrings: (state = {}) => state,
  recaptchaSiteKey: (state = {}) => state,
  salutationOptions: (state = {}) => state,
  [subscriptionWidgetApi.reducerPath]: subscriptionWidgetApi.reducer,
})
