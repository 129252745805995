import React from 'react'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useStoreSelector } from '../../store'
import type { RootState } from '../../store/reducers'

const is7RGdprPolicyRegex = /^https?:\/\/[\w\d._-]*sevenrooms[\w\d._-]*.com(:\d+)?\/gdpr-policy\//i

export function TermsComponent() {
  const {
    reservationWidgetSettings: {
      termsPolicyHolderName,
      termsOfServiceUrl,
      textCustomGdprPolicyLink,
      textCustomPolicyHolderName,
      textCustomPrivacyPolicyLink,
    },
    selectedLanguageStrings: {
      commonAndText,
      resWidgetCustomGdprPolicyLinkLabel,
      resWidgetCustomPrivacyPolicyLinkLabel,
      resWidgetPolicyDisclaimerText,
      resWidgetTermsOfServiceLinkLabel,
    },
  } = useStoreSelector((state: RootState) => state)

  const customPrivatePolicyAnchor = (
    <Anchor key="customPrivacyPolicyAnchor" href={textCustomPrivacyPolicyLink}>
      {resWidgetCustomPrivacyPolicyLinkLabel}
    </Anchor>
  )
  const termsOfServiceAnchor = (
    <Anchor key="grouptermsofServiceAnchor" href={termsOfServiceUrl}>
      {resWidgetTermsOfServiceLinkLabel}
    </Anchor>
  )
  const gdprAnchor = resWidgetCustomGdprPolicyLinkLabel && (
    <Anchor key="gdprAnchor" href={textCustomGdprPolicyLink}>
      {resWidgetCustomGdprPolicyLinkLabel}
    </Anchor>
  )
  const isSevenRoomsGdprPolicy = is7RGdprPolicyRegex.test(textCustomGdprPolicyLink)

  return (
    <Text fontSize="s" data-test="subscription-terms-and-policies">
      {`${resWidgetPolicyDisclaimerText} ${termsPolicyHolderName} `}
      {/* eslint-disable-next-line no-nested-ternary */}
      {textCustomPrivacyPolicyLink && isSevenRoomsGdprPolicy
        ? [
            termsOfServiceAnchor,
            gdprAnchor && ` ${commonAndText} `,
            gdprAnchor,
            ` ${commonAndText} ${textCustomPolicyHolderName} `,
            customPrivatePolicyAnchor,
          ]
        : textCustomPrivacyPolicyLink
        ? [
            termsOfServiceAnchor,
            ` ${commonAndText} ${textCustomPolicyHolderName} `,
            customPrivatePolicyAnchor,
            gdprAnchor && ` ${commonAndText} `,
            gdprAnchor,
          ]
        : [termsOfServiceAnchor, gdprAnchor && ` ${commonAndText} `, gdprAnchor]}
    </Text>
  )
}
