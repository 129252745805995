import { useCallback, useMemo, useState } from 'react'
import { parsePhoneNumber } from 'react-phone-number-input'
import type { subscriptionWidgetApi } from '@sevenrooms/core/api'
import type { CountryCode, SubscriptionWidget } from '@sevenrooms/core/domain'
import { useForm, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import {
  Button,
  Checkbox,
  FormBirthday,
  FormInput,
  FormPhoneNumberInput,
  FormReCaptcha,
  FormSelect,
  Label,
  MultiSelectTag,
  ErrorMessage,
} from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, notify, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { routes } from '@sevenrooms/routes'
import { reservationWidgetMessages } from '@sevenrooms/widget-reservations/reservationWidgetMessages'
import { useWidgetSubscriptionForm } from '../../forms/WidgetSubscription.zod'
import { useSubscriptionWidgetStore } from '../../store'
import { subscriptionWidgetMessages } from '../../subscriptionWidgetMessages'
import { TermsComponent } from './TermsComponent'

interface FormStateProps extends Omit<SubscriptionWidget.Signup, 'venueId' | 'birthdayDay' | 'birthdayMonth' | 'dietaryRestrictions'> {
  birthdayDay: string | null
  birthdayMonth: string | null
  dietaryRestrictions: SubscriptionWidget.Venue['dietaryRestrictions']
}

const labelTitle = (label: string, isRequired: boolean | null) => {
  if (!label) {
    return ''
  }
  switch (isRequired) {
    case true:
      return `${label}*`
    default:
      return label
  }
}

interface SubscribeComponentProps {
  signupMutation: ReturnType<typeof subscriptionWidgetApi.useSignupMutation>[0]
}

export function SubscribeComponent({ signupMutation }: SubscribeComponentProps) {
  const { formatMessage } = useLocales()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {
    venueInfo,
    subscriptionWidgetSettings: {
      formSettings: {
        isBirthday,
        isDietaryRestriction,
        isPolicyVenueMarketing,
        isPolicyVenueMarketingDefaultOn,
        isPolicyVenueGroupMarketing,
        isPolicyVenueGroupMarketingDefaultOn,
        isPolicySmsVenueMarketing,
        isPolicySmsVenueMarketingDefaultOn,
        isTailoredCommunicationOn,
        isTailoredCommunicationDefaultOn,
        isTailoredCommunicationDisabled,
        isPostalCode,
        isRecaptcha,
        isSalutation,
        redirectUrl,
      },
    },
    recaptchaSiteKey,
    salutationOptions,
  } = useSubscriptionWidgetStore()
  const params = new URLSearchParams(window.location.search)
  const {
    field,
    handleSubmit,
    formState: { errors },
  } = useForm(
    useWidgetSubscriptionForm({
      isBirthday,
      isDietaryRestriction,
      isPolicyVenueMarketing,
      isPolicyVenueMarketingDefaultOn,
      isPolicyVenueGroupMarketing,
      isPolicyVenueGroupMarketingDefaultOn,
      isPolicySmsVenueMarketing,
      isPolicySmsVenueMarketingDefaultOn,
      isPostalCode,
      isRecaptcha,
      isSalutation,
      redirectUrl,
      isTailoredCommunicationOn: isTailoredCommunicationOn || false,
      isTailoredCommunicationDefaultOn: isTailoredCommunicationDefaultOn || false,
      isTailoredCommunicationDisabled: isTailoredCommunicationDisabled || false,
    }),
    {
      defaultValues: {
        dietaryRestrictions: [],
        optInMarketingVenueGroup: isPolicyVenueGroupMarketingDefaultOn,
        optInMarketingVenue: isPolicyVenueMarketingDefaultOn,
        optInVenueSmsMarketing: isPolicySmsVenueMarketingDefaultOn,
        optInTailoredCommunication: isTailoredCommunicationDefaultOn,
        phoneLocale: venueInfo.countryCode,
        email: params.get('email') ?? undefined,
      },
    }
  )

  const optInError = useMemo(() => {
    const {
      optInMarketingVenue: venueOptInError,
      optInMarketingVenueGroup: venueGroupOptInError,
      optInVenueSmsMarketing: smsOptInError,
    } = errors
    return venueOptInError?.message ?? venueGroupOptInError?.message ?? smsOptInError?.message
  }, [errors])

  const dietaryRestrictionOptions = useMemo(
    () => venueInfo.dietaryRestrictions.map(i => ({ id: i.id, label: i.label, categoryId: 'dietaryRestrictions' })),
    [venueInfo.dietaryRestrictions]
  )

  const titleOptions = useMemo(
    () =>
      salutationOptions.map(i => ({
        id: i,
        label: i,
      })),
    [salutationOptions]
  )

  const [recaptcha, optInMarketingVenue, optInMarketingVenueGroup, optInVenueSmsMarketing] = useWatchMany(field, [
    'recaptcha',
    'optInMarketingVenue',
    'optInMarketingVenueGroup',
    'optInVenueSmsMarketing',
  ])
  const handlePhoneLocateChange = (newCountry?: CountryCode) => {
    field.prop('phoneLocale').set(newCountry ?? '')
  }
  const onClick = useCallback(
    async (formState: FormStateProps) => {
      setIsSubmitting(true)
      try {
        const [mm, dd] = [formState.birthdayMonth, formState.birthdayDay]
        const birthday = mm && dd ? DateOnly.from(`1968-${mm}-${dd}`).toJsDate() : undefined
        const dietaryRestrictions = formState.dietaryRestrictions?.map((i: { id: string }) => i.id)
        const phoneLocale = formState.phoneLocale?.toUpperCase()
        const phone = (formState.phone && parsePhoneNumber(formState.phone)?.nationalNumber) || ''
        await signupMutation({
          ...formState,
          venueId: venueInfo.id,
          birthday,
          dietaryRestrictions,
          phoneLocale,
          phone,
        })
          .unwrap()
          .catch(err => notify({ content: err, type: 'error' }))
      } catch (error) {
        if (error instanceof Error) {
          notify({ content: error, type: 'error' })
        } else {
          notify({ content: formatMessage(subscriptionWidgetMessages.resConfirmPageError), type: 'error' })
        }
      }
      setIsSubmitting(false)
    },
    [formatMessage, signupMutation, venueInfo.id]
  )

  const { matchQuery } = useNavigation()
  const { embed } = matchQuery(routes.explore.subscription) ?? {}
  const containerStyling = embed
    ? ({ pl: 'lm', pr: 'lm', pb: 'lm' } as const)
    : ({
        p: 'lm',
        m: 'xxl',
        borderRadius: 's',
        borderColor: 'borders',
      } as const)

  return (
    <Box width="496px" {...containerStyling}>
      <VStack spacing="m">
        {!embed && (
          <HStack>
            <Text color="primaryFont" fontSize="xxl" fontWeight={600}>
              {formatMessage(subscriptionWidgetMessages.emailSubscriptionHeader)}
            </Text>
          </HStack>
        )}

        <VStack spacing="sm" pt="s">
          {isPolicySmsVenueMarketing && (
            <Checkbox
              field={field.prop('optInVenueSmsMarketing')}
              info={
                <>
                  {formatMessage(subscriptionWidgetMessages.policyVenueSpecificSmsMarketing, {
                    venue_name: venueInfo.name,
                    venue: venueInfo.name,
                  })}
                </>
              }
              disabled={isSubmitting}
              hideErrorMessage
            >
              <Text textStyle="body1" lineHeight="l">
                {formatMessage(subscriptionWidgetMessages.subscriptionWidgetVenueSmsOptIn, {
                  venue_name: venueInfo.name,
                  venue: venueInfo.name,
                })}
              </Text>
            </Checkbox>
          )}
          {isPolicyVenueMarketing && (
            <Checkbox
              field={field.prop('optInMarketingVenue')}
              info={
                <>
                  {formatMessage(subscriptionWidgetMessages.policyVenueSpecificMarketing, {
                    venue_name: venueInfo.name,
                    venue: venueInfo.name,
                  })}
                </>
              }
              disabled={isSubmitting}
              hideErrorMessage
              onChange={ev => {
                const { checked } = ev.target
                if (!checked) {
                  field.prop('optInMarketingVenueGroup').set(false)
                }
              }}
            >
              <Text textStyle="body1" lineHeight="l" data-test="">
                {formatMessage(subscriptionWidgetMessages.subscriptionWidgetVenueEmailOptIn, {
                  venue_name: venueInfo.name,
                  venue: venueInfo.name,
                })}
              </Text>
            </Checkbox>
          )}
          {isPolicyVenueGroupMarketing && (
            <Checkbox
              field={field.prop('optInMarketingVenueGroup')}
              info={<>{formatMessage(subscriptionWidgetMessages.policyVenueGroupMarketing)}</>}
              disabled={isSubmitting}
              hideErrorMessage
              onChange={ev => {
                const { checked } = ev.target
                if (checked) {
                  field.prop('optInMarketingVenue').set(true)
                }
              }}
            >
              <Text textStyle="body1" lineHeight="l">
                {formatMessage(subscriptionWidgetMessages.subscriptionWidgetGroupEmailOptIn)}
              </Text>
            </Checkbox>
          )}
          {isTailoredCommunicationOn && (
            <Checkbox
              field={field.prop('optInTailoredCommunication')}
              info={<>{formatMessage(reservationWidgetMessages.textTailoredCommunicationOptInBody)}</>}
              disabled={isSubmitting}
              hideErrorMessage
              onChange={ev => {
                const { checked } = ev.target
                if (checked) {
                  field.prop('optInTailoredCommunication').set(true)
                }
              }}
            >
              <Text textStyle="body1" lineHeight="l">
                {formatMessage(reservationWidgetMessages.textTailoredCommunicationOptInLabel)}
              </Text>
            </Checkbox>
          )}
          {optInError && <ErrorMessage>{optInError}</ErrorMessage>}
        </VStack>

        <DividerLine ml="none" mr="none" />

        {isSalutation !== null && (
          <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetSalutationLabel), isSalutation)} primary="">
            <FormSelect
              field={field.prop('salutation')}
              options={titleOptions}
              id="title"
              placeholder={formatMessage(subscriptionWidgetMessages.resWidgetSalutationLabel)}
              searchable={false}
              disabled={isSubmitting}
            />
          </Label>
        )}
        <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetFirstNameLabel), true)} primary="">
          <FormInput field={field.prop('firstName')} inputMode="text" disabled={isSubmitting} />
        </Label>
        <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetLastNameLabel), true)} primary="">
          <FormInput field={field.prop('lastName')} inputMode="text" disabled={isSubmitting} />
        </Label>
        <Label
          secondary={labelTitle(
            formatMessage(subscriptionWidgetMessages.resWidgetEmailLabel),
            !!(optInMarketingVenue || optInMarketingVenueGroup)
          )}
          primary=""
        >
          <FormInput field={field.prop('email')} inputMode="email" disabled={isSubmitting} />
        </Label>
        <Label
          secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetPhoneNumberLabel), !!optInVenueSmsMarketing)}
          primary=""
        >
          <FormPhoneNumberInput
            field={field.prop('phone')}
            defaultCountryCode={venueInfo.countryCode as CountryCode}
            onCountryChange={handlePhoneLocateChange}
            hideCountryFlags={venueInfo.hideCountryFlags}
            disabled={isSubmitting}
          />
        </Label>
        {isBirthday !== null && (
          <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetBirthdayLabel), isBirthday)} primary="">
            <FormBirthday
              dayField={field.prop('birthdayDay')}
              monthField={field.prop('birthdayMonth')}
              locale={venueInfo.locale}
              disabled={isSubmitting}
              fullWidth
            />
          </Label>
        )}
        {isPostalCode !== null && (
          <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetPostalCodeLabel), isPostalCode)} primary="">
            <FormInput field={field.prop('postalCode')} disabled={isSubmitting} />
          </Label>
        )}
        {isDietaryRestriction !== null && (
          <Label secondary={labelTitle(formatMessage(subscriptionWidgetMessages.resWidgetDietaryRestrictionsLabel), false)} primary="">
            <MultiSelectTag
              field={field.prop('dietaryRestrictions')}
              options={dietaryRestrictionOptions}
              id="dietary-restrictions"
              placeholder={formatMessage(subscriptionWidgetMessages.resConfirmPageDietaryRestrictQuestion)}
              categories={[{ id: 'dietaryRestrictions', name: '', color: '#3A4049' }]}
              disabled={isSubmitting}
            />
          </Label>
        )}
        {isRecaptcha && recaptchaSiteKey && <FormReCaptcha field={field.prop('recaptcha')} siteKey={recaptchaSiteKey} />}
        <HStack pt="s">
          <Button
            data-test="subscription-submit-button"
            variant="primary"
            fullWidth
            type="submit"
            onClick={handleSubmit(onClick)}
            disabled={(isRecaptcha && !recaptcha) || isSubmitting}
          >
            {formatMessage(subscriptionWidgetMessages.resWidgetCheckoutSubmitButtonLabel)}
          </Button>
        </HStack>
        <VStack spacing="s">
          <TermsComponent />
          {isPolicySmsVenueMarketing && optInVenueSmsMarketing && (
            <Text fontSize="s">
              <HTMLContent content={formatMessage(subscriptionWidgetMessages.policyUsTwilioSmsOptInSubfooter)} />
            </Text>
          )}
        </VStack>
      </VStack>
    </Box>
  )
}
