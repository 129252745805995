/* eslint-disable camelcase */
import {
  SubscriptionWidgetAdapter,
  type SubscriptionWidgetApi,
  type ReservationWidget,
  ReservationWidgetAdapter,
  type SubscriptionWidgetSettings,
} from '@sevenrooms/core/api'

declare const window: {
  PRELOADED: {
    widget_settings: {
      reservation_widget: ReservationWidget.Settings
      subscription_widget: SubscriptionWidgetSettings
    }
    selected_language_strings: SubscriptionWidgetApi.LanguageStrings
    base_venue: SubscriptionWidgetApi.Venue
    client_email: string
    client_id: string
    recaptcha_site_key: string
    salutation_options: string[]
  }
}

export const preloadedState = {
  venueInfo: SubscriptionWidgetAdapter.venueToClient(window.PRELOADED.base_venue),
  reservationWidgetSettings: ReservationWidgetAdapter.settingsToClient(window.PRELOADED.widget_settings.reservation_widget),
  subscriptionWidgetSettings: SubscriptionWidgetAdapter.settingsToClient(window.PRELOADED.widget_settings.subscription_widget),
  selectedLanguageStrings: SubscriptionWidgetAdapter.stringsToClient(window.PRELOADED.selected_language_strings),
  salutationOptions: window.PRELOADED.salutation_options,
  recaptchaSiteKey: window.PRELOADED.recaptcha_site_key,
}

export type PreloadedState = typeof preloadedState
